import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios, { CancelTokenStatic } from 'axios'
import SecureLS from 'secure-ls'
import { subtotalStore } from '@/stores/subtotal'
import { authStore } from '@/stores/auth'
import { headerStore } from '@/stores/header'
import { Level } from '@/types/completion2/level'
import { Room } from '@/types/completion2/room'
import { Group } from '@/types/completion2/group'
import { Element } from '@/types/completion2/element'
import { Subelement } from '@/types/completion2/subelement'
import { Option } from '@/types/completion2/option'
import { EmptyWallOutlets } from '@/globalVariables'

const ls = new SecureLS({ isCompression: false })

export const completionTwoStore = defineStore('completionTwo', () => {
  const loading = ref<{ levels:boolean, rooms:boolean, groups:boolean, elements:boolean, subelements:boolean, options: number[], selectedOption: number[]}>({
    levels: false,
    rooms: false,
    groups: false,
    elements: false,
    subelements: false,
    options: [],
    selectedOption: []
  })

  const saving = ref<{ subelementId:number, optionId:number }>({ subelementId: 0, optionId: 0 })
  const menuDisabled = computed(() => { return loading.value.levels || loading.value.rooms || loading.value.groups || loading.value.elements })
  const phaseState = ref('loading')
  const phaseIsClosed = computed(() => ['closed', 'processed', 'expired'].includes(phaseState.value) || headerStore().ordersReceived.completion)
  const isInternalTestAccount = computed(() => ls.get('demoAccount').toLowerCase() === authStore().user.email.toLowerCase())

  const levels = ref<Level[]>([])
  const rooms = ref<Room[]>([])
  const groups = ref<Group[]>([])
  const elements = ref<Element[]>([])
  const subelements = ref<Subelement[]>([])

  const emptyLayout = ref(false)

  const cancelToken: CancelTokenStatic = axios.CancelToken
  let source = cancelToken.source()

  const current = ref<{ level:number|null, room:number|null, group:number|null, elementIndex:number|null, subelement:number|null }>({
    level: null,
    room: null,
    group: null,
    elementIndex: null,
    subelement: null
  })

  const optionIdsToSelect = ref<number[]>([])

  const currentLayoutId = computed(() => {
    return rooms.value.find(r => r.id === completionTwoStore().current.room)?.layoutId || null
  })

  const currentElement = computed(() => {
    if (current.value.elementIndex !== null) {
      return elements.value[current.value.elementIndex]
    } else {
      return null
    }
  })

  function cancelAllPreviousRequests () : void {
    source.cancel()
    source = cancelToken.source()
  }

  function getLevels () : void {
    loading.value.levels = true
    levels.value = []
    rooms.value = []
    groups.value = []
    elements.value = []
    subelements.value = []

    axios.get(process.env.VUE_APP_API_URL + '/building/' + ls.get('building').id + '/levels').then((r) => {
      levels.value = r.data

      if (levels.value.length) {
        setLevel(ls.get('completion2CurrentLevel') ? ls.get('completion2CurrentLevel') : levels.value[0].id)
      }
    }).finally(() => {
      loading.value.levels = false
    })
  }

  function setLevel (id:number|null) : void {
    cancelAllPreviousRequests()
    current.value.level = id
    ls.set('completion2CurrentLevel', current.value.level)

    setRoom(null)
    getRooms()
  }

  function getRooms () : void {
    loading.value.rooms = true
    rooms.value = []
    groups.value = []
    elements.value = []
    subelements.value = []

    axios.get(process.env.VUE_APP_API_URL + '/building/' + ls.get('building').id + '/level/' + current.value.level + '/rooms').then((r) => {
      rooms.value = r.data

      if (rooms.value.length) {
        setRoom(rooms.value[0].id)
      }
    }).finally(() => {
      loading.value.rooms = false
    })
  }

  function setRoom (id:number|null) : void {
    cancelAllPreviousRequests()
    current.value.room = id

    setGroup(null)

    if (current.value.room) {
      getGroups()
    }
  }

  function getGroups () : void {
    loading.value.groups = true
    groups.value = []
    elements.value = []
    subelements.value = []
    emptyLayout.value = false

    axios.post(process.env.VUE_APP_API_URL + '/completion2/groups', { lid: currentLayoutId.value }).then((r) => {
      groups.value = r.data

      if (groups.value.length) {
        setGroup(groups.value[0].id)
      } else {
        emptyLayout.value = true
      }
    }).finally(() => {
      loading.value.groups = false
    })
  }

  function setGroup (id:number|null) : void {
    cancelAllPreviousRequests()
    current.value.group = id

    setElement(null)

    if (current.value.group) {
      getElements()
    }
  }

  function getElements () : void {
    loading.value.elements = true
    elements.value = []
    subelements.value = []

    axios.post(process.env.VUE_APP_API_URL + '/completion2/elements', { lid: currentLayoutId.value, gid: current.value.group }).then((r) => {
      elements.value = r.data

      if (elements.value.length) {
        setElement(0)
      }
    }).finally(() => {
      loading.value.elements = false
    })
  }

  function setElement (index:number|null) : void {
    cancelAllPreviousRequests()
    current.value.elementIndex = index

    setSubElement(null)

    if (current.value.elementIndex !== null) {
      getSubElements()
    }
  }

  function getSubElements () : void {
    if (currentElement.value) {
      loading.value.subelements = true
      subelements.value = []
      axios.post(process.env.VUE_APP_API_URL + '/completion2/subelements', { eid: currentElement.value.id }, { cancelToken: source.token }).then((r) => {
        subelements.value = r.data

        if (subelements.value.length) {
          setSubElement(subelements.value[0].id)

          getOptions(subelements.value[0].id, 0)
        }
      }).finally(() => {
        loading.value.subelements = false
      })
    }
  }

  function setSubElement (id:number|null) : void {
    current.value.subelement = id
  }

  function getOptions (subelementId:number, subelementIndex:number) : void {
    if (currentElement.value) {
      loading.value.options.push(subelementId)
      axios.post(process.env.VUE_APP_API_URL + '/completion2/options', {
        rid: current.value.room,
        lid: currentLayoutId.value,
        eid: currentElement.value.id,
        epos: currentElement.value.position,
        sid: subelementId
      }, { cancelToken: source.token }).then((r) => {
        subelements.value[subelementIndex].options = r.data
        getSelectedOption(subelementId, subelementIndex)
      }).finally(() => {
        loading.value.options.splice(loading.value.options.indexOf(subelementId), 1)
      })
    }
  }

  function getSelectedOption (subelementId:number, subelementIndex:number) : void {
    if (currentElement.value) {
      loading.value.selectedOption.push(subelementId)
      axios.post(process.env.VUE_APP_API_URL + '/completion2/option/selected', {
        rid: current.value.room,
        lid: currentLayoutId.value,
        eid: currentElement.value.id,
        epos: currentElement.value.position,
        sid: subelementId
      }, { cancelToken: source.token }).then((r) => {
        subelements.value[subelementIndex].selected_option = r.data

        if (subelements.value[subelementIndex].selected_option.id === null) {
          let defaultOptionSelected = false
          let automaticallyOptionSelected = false
          let fixedOptionSelected = false

          subelements.value[subelementIndex].options.forEach((o:Option) => {
            if (optionIdsToSelect.value.includes(o.id) && !phaseIsClosed.value) {
              selectOption(subelements.value[subelementIndex], subelementIndex, o)
              automaticallyOptionSelected = true
              optionIdsToSelect.value.splice(optionIdsToSelect.value.indexOf(o.id), 1)
            } else if (o.default && !EmptyWallOutlets.includes(o.externalRef) && !phaseIsClosed.value && !automaticallyOptionSelected) {
              selectOption(subelements.value[subelementIndex], subelementIndex, o)
              defaultOptionSelected = true
            } else if (subelements.value[subelementIndex].fixed) {
              selectOption(subelements.value[subelementIndex], subelementIndex, o)
              fixedOptionSelected = true
            }
          })

          if (!defaultOptionSelected && !automaticallyOptionSelected && !fixedOptionSelected && subelementIndex + 1 <= subelements.value.length - 1 && currentElement.value?.selectorType !== 'electric') {
            getOptions(subelements.value[subelementIndex + 1].id, subelementIndex + 1)
          }
        } else {
          if (subelementIndex + 1 <= subelements.value.length - 1) {
            getOptions(subelements.value[subelementIndex + 1].id, subelementIndex + 1)
          }
        }
      }).finally(() => {
        loading.value.selectedOption.splice(loading.value.selectedOption.indexOf(subelementId), 1)
      })
    }
  }

  function selectOption (subelement:Subelement, subelementIndex:number, option:Option) : void {
    if (currentElement.value) {
      saving.value.subelementId = subelement.id
      saving.value.optionId = option.id
      axios.post(process.env.VUE_APP_API_URL + '/completion2/option/store', {
        pid: 2,
        rid: current.value.room,
        lid: currentLayoutId.value,
        gid: current.value.group,
        eid: currentElement.value.id,
        epos: currentElement.value.position,
        sid: subelement.id,
        oid: option.id
      }, { cancelToken: source.token }).then(() => {
        subelements.value[subelementIndex].selected_option = option
        subtotalStore().getSubtotals()

        if (option.automaticallySelect.length) {
          option.automaticallySelect.forEach((optionId: number) => {
            optionIdsToSelect.value.push(optionId)
          })
        }

        if (subelementIndex + 1 <= subelements.value.length - 1) {
          getOptions(subelements.value[subelementIndex + 1].id, subelementIndex + 1)
        }
      }).finally(() => {
        saving.value.subelementId = 0
        saving.value.optionId = 0
      })
    }
  }

  function removeWallOutlet (subelement:Subelement, subelementIndex:number) : void {
    if (currentElement.value) {
      saving.value.subelementId = subelement.id
      axios.post(process.env.VUE_APP_API_URL + '/completion2/walloutlet/remove', {
        rid: current.value.room,
        lid: currentLayoutId.value,
        eid: currentElement.value.id,
        epos: currentElement.value.position,
        sid: subelement.id
      }).then(() => {
        subelements.value[subelementIndex].selected_option = { id: null, name: 'Maak een keuze..', price: 0, vat_rate: 21 }
        subtotalStore().getSubtotals()
      }).finally(() => {
        saving.value.subelementId = 0
      })
    }
  }

  function getPhaseState () : void {
    axios.get(process.env.VUE_APP_API_URL + '/phase/2/closed').then((r) => {
      phaseState.value = r.data.status
    })
  }

  return { loading, saving, menuDisabled, phaseState, phaseIsClosed, isInternalTestAccount, levels, rooms, groups, elements, subelements, current, currentElement, emptyLayout, getLevels, setLevel, setRoom, setGroup, setElement, selectOption, removeWallOutlet, getPhaseState }
})

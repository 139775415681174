import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import axios from 'axios'
import SecureLS from 'secure-ls'
import { authStore } from '@/stores/auth'
import { subtotalStore } from '@/stores/subtotal'
import { headerStore } from '@/stores/header'
import { errorNotification, successNotification } from '@/functions/notification'
import { Option } from '@/types/carcass/option'

const ls = new SecureLS({ isCompression: false })

export const carcassStore = defineStore('carcass', () => {
  const loading = ref(false)
  const savingId = ref(0)
  const removingId = ref(0)
  const options = ref<Option[]>([])
  const agreeToTerms = ref(false)
  const creatingOrder = ref(false)

  const selectedOptionIds = computed(() => {
    const selectedOptions:number[] = []

    options.value.forEach((option:Option) => {
      if (option.selected) {
        selectedOptions.push(option.id)
      }
    })

    return selectedOptions
  })

  const isDisabled = computed(() => {
    interface OptionStates { [key: string]: boolean; }
    const optionStates:OptionStates = {}

    options.value.forEach((option:Option) => {
      optionStates[option.id.toString()] = option.unavailableWith.some((unavailableWithOptionId:number) => selectedOptionIds.value.includes(unavailableWithOptionId)) ||
        (option.onlyAvailableWith.length ? !option.onlyAvailableWith.some((onlyAvailableWithOptionId:number) => selectedOptionIds.value.includes(onlyAvailableWithOptionId)) : false)
    })
    return optionStates
  })

  const isInternalTestAccount = computed(() => {
    return ls.get('demoAccount').toLowerCase() === authStore().user.email.toLowerCase()
  })

  const phaseState = ref<string>('loading')
  const phaseIsClosed = computed(() => {
    return ['closed', 'processed', 'expired'].includes(phaseState.value) || headerStore().ordersReceived.carcass
  })

  function getOptions () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/carcass/options').then((r) => {
      options.value = r.data
    }).finally(() => {
      loading.value = false
    })
  }

  function selectOption (selectedOption:Option) : void {
    savingId.value = selectedOption.id
    axios.post(process.env.VUE_APP_API_URL + '/carcass/option/store', { oid: selectedOption.id }).then(() => {
      subtotalStore().getSubtotals()
      options.value.forEach((option:Option) => {
        if (option.id === selectedOption.id) {
          option.selected = true
        }
      })
    }).finally(() => {
      savingId.value = 0
    })
  }

  function unselectOption (unselectedOption:Option) : void {
    removingId.value = unselectedOption.id
    axios.post(process.env.VUE_APP_API_URL + '/carcass/option/remove', { oid: unselectedOption.id }).then(() => {
      subtotalStore().getSubtotals()
      options.value.forEach((option:Option) => {
        if (option.id === unselectedOption.id) {
          option.selected = false
        }

        // Check if there are options which are only available if unselected option is selected.
        if (option.onlyAvailableWith.length && option.onlyAvailableWith.includes(unselectedOption.id)) {
          option.selected = false
          axios.post(process.env.VUE_APP_API_URL + '/carcass/option/remove', { oid: option.id }).then(() => {
            subtotalStore().getSubtotals()
          })
        }
      })
    }).finally(() => {
      removingId.value = 0
    })
  }

  function getPhaseState () : void {
    loading.value = true
    axios.get(process.env.VUE_APP_API_URL + '/phase/1/closed').then((r) => {
      phaseState.value = r.data.status
    })
  }

  function createOrder () : void {
    if (agreeToTerms.value) {
      creatingOrder.value = true
      axios.get(process.env.VUE_APP_API_URL + '/carcass/order').then(() => {
        headerStore().getOrdersReceived()
        phaseState.value = 'closed'
        successNotification('Bestelling geplaatst!', 'Uw bestelling voor de ruwbouw opties is succesvol geplaatst.')
      }).catch(() => {
        errorNotification('Bestelling NIET geplaatst!', 'Er is iets mis gegaan tijdens het plaatsen van uw bestelling. Probeer het nogmaals.')
      }).finally(() => {
        creatingOrder.value = false
      })
    }
  }

  return { loading, savingId, removingId, options, agreeToTerms, creatingOrder, selectedOptionIds, isDisabled, isInternalTestAccount, phaseState, phaseIsClosed, getOptions, selectOption, unselectOption, getPhaseState, createOrder }
})
